import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És la Lepiota més cercada per la seva gran mida. El capell mesura fins a 25 cm de diàmetre, al principi tancat i plegat cap al peu, adquirint una forma semblant a una porra. A poc a poc, el capell es va obrint i aplanant però conservant un mamelló central no gaire alt. La superfície al principi és llisa i marró, però quan s’estén el capell es romp la cutícula en esquames disperses a excepció del disc central , de forma que el capell apareix blanc-rosat amb esquames marrons disperses. Les làmines són nombroses, atapeïdes i blanques. El peu s’engruixa a la base, és molt llarg, de 15 a 30 cm i de 1 a 2 cm de diàmetre. A més, és típicament atigrat de color marró clar amb el fons blanc-rosat. Està proveït d’un gran anell membranós, fàcilment desplaçable pel peu. Les espores són blanques en massa, el·líptiques, de 13-20 x 9-13 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      